<template>
  <div>
    <UpdatePostForm v-if="ready" :post="post" :permissions="permissions"/>
    <div v-else>
      <b-row>
        <b-col/>
        <b-col cols="1">
          <AppPulsingIcon/>
        </b-col>
        <b-col/>
      </b-row>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

import UpdatePostForm from "@/components/posts/UpdatePostForm";
import AppPulsingIcon from "@/components/AppPulsingIcon";

export default {
  components: {
    UpdatePostForm,
    AppPulsingIcon,
  },
  props: {
    uid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      ready: false,
    };
  },
  computed: {
    ...mapState({
      post: (state) => state.posts.post,
      permissions: (state) => state.permissions.all,
    }),
  },
  async created() {
    try {
      await Promise.all([
        this.$store.dispatch("posts/fetchPost", this.uid),
        this.$store.dispatch("permissions/fetchPermissions"),
      ]);
    } catch (e) {
      await this.$swal.fire({
        icon: "error",
        title: "Une erreur est survenue lors de la récupération du poste",
        text: e.message,
      });
      await this.$router.push({name: 'posts'})
      return;
    }
    this.ready = true;
  },
};
</script>

