<template>
  <b-modal
      id="add-permissions-modal"
      title="Ajouter des permissions"
      size="xl"
      @hidden="clearInput"
  >
    <b-row>
      <b-col>
        <b-input-group>
          <b-form-input
              autofocus
              v-model="filterSlug"
              placeholder="Rechercher"
          />
        </b-input-group>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col>
        <b-table
            hover
            striped
            borderless
            head-variant="light"
            :items="filteredPermissions"
            :fields="fields"
            v-if="filteredPermissions.length > 0"
        >
          <template #cell(name)="permission">
            {{ permissionToString(permission.item) }}
          </template>

          <template #cell(edit)="permission">
            <div class="row justify-content-end mr-0">
              <AddButton
                  class="no-border"
                  @add="$emit('add-permission', permission.item)"
              />
            </div>
          </template>
        </b-table>
        <span v-else>Aucune autre permission à ajouter</span>
      </b-col>
    </b-row>
    <template #modal-footer="{ cancel }">
      <b-button variant="secondary" @click="cancel()">Fermer</b-button>
    </template>
  </b-modal>
</template>

<script>
import AddButton from "@/components/buttons/AddButton";
import {permissionToString} from "@/functions";

export default {
  props: {
    permissions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      filterSlug: '',
      fields: [
        {key: "name", label: "Nom"},
        {key: "edit", label: ""},
      ],
    };
  },
  computed: {
    sortedPermissions() {
      return this.permissions
          .slice()
          .sort((a, b) =>
              a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1
          );
    },
    filteredPermissions() {
      if (this.filterSlug) {
        const regex = new RegExp(this.filterSlug, 'i');
        return this.sortedPermissions.filter((permission) =>
            permissionToString(permission).match(regex)
        );
      }
      return this.sortedPermissions;
    },
  },
  methods: {
    permissionToString,
    clearInput() {
      this.filterSlug = '';
    },
  },
  components: {
    AddButton,
  },
};
</script>

