<template>
  <div>
    <b-form @submit.prevent="updatePost">
      <b-form-group label="Nom" label-for="name-input">
        <b-form-input
            id="name-input"
            v-model="$v.p.name.$model"
            type="text"
            required
            placeholder="Renseignez un nom"
            :state="$v.p.name.$dirty ? !$v.p.name.$invalid : null"
        />
        <b-form-invalid-feedback :state="!$v.p.name.$invalid">
          <span v-if="!$v.p.name.required">Vous devez renseigner ce champ</span>
          <span v-if="!$v.p.name.name">Ce champ ne peut contenir que des caratères alphabétiques</span>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label="Diminutif" label-for="short-name-input">
        <b-form-input
            id="short-name-input"
            v-model="$v.p.shortName.$model"
            type="text"
            required
            placeholder="Renseignez un diminutif"
            :state="$v.p.shortName.$dirty ? !$v.p.shortName.$invalid : null"
        />
        <b-form-invalid-feedback :state="!$v.p.shortName.$invalid">
          <span v-if="!$v.p.shortName.required">Vous devez renseigner ce champ</span>
          <span v-else-if="!$v.p.shortName.name">Ce champ ne peut contenir que des caratères alphabétiques</span>
          <span v-else-if="!$v.p.shortName.maxLength">Longueur maximale dépassée (10 caractères)</span>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group>
        <template slot="label">
          Permissions
          <font-awesome-icon
              icon="plus-circle"
              :style="{ color: 'green' }"
              v-b-modal.add-permissions-modal
          />
        </template>
        <PostPermissionsList
            :permissions="p.permissions"
            @remove="removePermission"
        />
      </b-form-group>
      <b-button type="submit" variant="primary" :disabled="$v.$invalid">
        Envoyer
      </b-button>
    </b-form>
    <AddPermissionsModal
        :permissions="nonPostPermissions"
        @add-permission="addPermission"
    />
  </div>
</template>

<script>
import {maxLength, required} from "vuelidate/lib/validators";
import {name} from "@/validators";

import AddPermissionsModal from "./modals/AddPermissionsModal";
import PostPermissionsList from "./PostPermissionsList";

export default {
  props: {
    post: {
      type: Object,
      required: true,
    },
    permissions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      p: JSON.parse(JSON.stringify(this.post)), // deep copy state to avoid mutation
    };
  },
  computed: {
    nonPostPermissions() {
      return this.permissions.filter(
          (permission) =>
              !this.p.permissions.some(
                  (postPermission) => permission.uid === postPermission.uid
              )
      );
    },
  },
  validations: {
    p: {
      name: {required, name},
      shortName: {required, name, maxLength: maxLength(10)},
    },
  },
  methods: {
    async updatePost() {
      const permissionsToAdd = this.p.permissions.filter(
          (pPermmission) =>
              !this.post.permissions.some(
                  (postPermission) => pPermmission.uid === postPermission.uid
              )
      );

      const permissionsToRemove = this.post.permissions.filter(
          (postPermission) =>
              !this.p.permissions.some(
                  (pPermission) => postPermission.uid === pPermission.uid
              )
      );

      try {
        await Promise.all([
          this.$store.dispatch("posts/updatePost", this.p),
          this.$store.dispatch("posts/addPermissions", {
            post: this.post,
            permissions: permissionsToAdd,
          }),
          this.$store.dispatch("posts/removePermissions", {
            post: this.post,
            permissions: permissionsToRemove,
          }),
        ]);
      } catch (e) {
        await this.$swal.fire({
          icon: "error",
          title: "Une erreur est survenue lors de la mise à jour du poste",
          text: e.message,
        });
        return;
      }
      await this.$swal.fire({
        icon: "success",
        title: "Poste modifié",
      });
    },
    addPermission(permission) {
      this.p.permissions.push(permission);
    },
    removePermission(permission) {
      this.p.permissions = this.p.permissions.filter(
          (pPermission) => pPermission.uid !== permission.uid
      );
    },
  },
  components: {
    PostPermissionsList,
    AddPermissionsModal,
  },
};
</script>
