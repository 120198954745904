<template>
  <b-row>
    <b-col v-if="permissions.length > 0">
      <RemovableBadge
          class="m-1"
          v-for="permission in permissions"
          :key="permission.uid"
          @remove="$emit('remove', permission)"
      >
        {{ permissionToString(permission) }}
      </RemovableBadge>
    </b-col>
    <b-col v-else>
      <p>Aucune permission n'est liée à ce post</p>
    </b-col>
  </b-row>
</template>

<script>
import RemovableBadge from "@/components/RemovableBadge";
import {permissionToString} from "@/functions";

export default {
  props: {
    permissions: {
      type: Array,
      required: true,
    },
  },
  computed: {
    sortedPermissions() {
      return this.permissions
          .slice()
          .sort((a, b) =>
              a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1
          );
    },
  },
  methods: {
    permissionToString,
  },
  components: {
    RemovableBadge,
  },
};
</script>
